/**
 * Gives information about the Users vertical and horizontal scrollposition
 * @callback Callback
 * @param {React.DependencyList} deps
 * @param {number} timeout
 * @returns {void}
 *
 * @see https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
 */
/* global NodeJS */
import * as React from 'react';

interface ScrollPosition {
	x: number;
	y: number;
}

interface CallbackArgs {
	prevPosition: ScrollPosition;
	currentPosition: ScrollPosition;
	direction: 'UP' | 'DOWN';
}

type Callback = ((scrollPos: CallbackArgs) => void);

export function useScrollPosition (callback: Callback, deps: React.DependencyList, timeout: number = 100): void {
	const initialPosition = React.useRef(getScrollPosition());

	let throttleTimeout: NodeJS.Timeout | null = null;

	const handleCallback = React.useCallback(() => {
		const currentPosition = getScrollPosition();
		const direction = currentPosition.y > initialPosition.current.y ? 'UP' : 'DOWN';

		callback({ prevPosition: initialPosition.current, currentPosition, direction });

		initialPosition.current = currentPosition;

		throttleTimeout = null;
	}, [callback]);

	React.useLayoutEffect(() => {
		const handleScroll = () => {
			if (timeout) {
				if (throttleTimeout === null) {
					throttleTimeout = setTimeout(handleCallback, timeout);
				}
			}
			else {
				handleCallback();
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			if (throttleTimeout !== null) {
				clearTimeout(throttleTimeout);
			}
			window.removeEventListener('scroll', handleScroll);
		};
	}, deps);
}

function getScrollPosition (): ScrollPosition {
	if (typeof window === 'undefined') {
		return { x: 0, y: 0 };
	}
	return { x: window.scrollX, y: window.scrollY };
}
