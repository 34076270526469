/* eslint-disable react/button-has-type */
// This Rule was disabled for this file because it is secured by TS
// https://github.com/yannickcr/eslint-plugin-react/issues/1555
import clsx from 'clsx';
import * as React from 'react';

import * as styles from './styles.module.scss';

interface ButtonProps {
	type: 'submit' | 'reset' | 'button';
	role: 'primary' | 'secondary' | 'textual' ;
	onClick? (): void;
	negative?: boolean;
	disabled?: boolean;
	inverted?: boolean;
}

export const Button: React.FunctionComponent<ButtonProps> = ({ children, negative, role, type, disabled, inverted, onClick }) => (
	<button
		className={clsx(
			styles.button,
			styles[role],
			{
				[styles.negative]: negative,
				[styles.disabled]: disabled,
				[styles.inverted]: inverted
			}
		)
		}
		type={type}
		onClick={onClick}
	>
		{children}
	</button>
);
