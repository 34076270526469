import clsx from 'clsx';
import * as React from 'react';

import imgWhite from '@assets/images/logo-white.png';
import img from '@assets/images/logo.png';
import { Button } from '@components/Button';
import { Navigation } from '@components/Navigation';
import { useScrollPosition } from '@hooks/useScrollposition';

import * as styles from './styles.module.scss';

interface NavigationProps {
	activeAreaId?: string;
	onlyColoredLogo?: boolean;

	menuLinks?: {
		href: string;
		title: string;
	}[];

	ctaText?: string;
}

export const Header: React.FunctionComponent<NavigationProps> = ({ menuLinks, ctaText, onlyColoredLogo }) => {
	const [isScrolling, setIsScrolling] = React.useState(false);
	useScrollPosition(({ currentPosition }) => ((currentPosition.y > 0) ? setIsScrolling(true) : setIsScrolling(false)), []);

	return (
		<header className={clsx(styles.backdrop, isScrolling && styles.isScrolling)}>
			<div className={clsx(styles.header, isScrolling && styles.isScrolling)}>
				<div className={styles.logoWrap}>
					{(isScrolling || onlyColoredLogo) ? <img src={img} /> : <img src={imgWhite} />}
				</div>
				{ctaText && <Button role="textual" inverted={!isScrolling} type="button">{ctaText}</Button>}
				{menuLinks && <Navigation menuLinks={menuLinks} buttonInverted={!isScrolling} ctaText={ctaText ?? ''} />}
			</div>
		</header>
	);
};
